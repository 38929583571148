












import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import { BlogFilter } from 'client-website-ts-library/filters';
import { BlogType, WebsiteLevel } from 'client-website-ts-library/types';
import Blogs from '../components/Blogs.vue';

@Component({
  components: {
    Blogs,
  },
})
export default class Blog extends Mixins(View) {
  private filter = new BlogFilter({
    SearchLevel: WebsiteLevel.Profile,
    SearchGuid: 'C7894578-A495-4CC1-A0B3-656F0684B2C0',
    Types: [BlogType.Estate, BlogType.Invest, BlogType.Landlord, BlogType.Market, BlogType.Social, BlogType.Suburb],
  });
}
